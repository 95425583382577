import React from "react"
import Modal from "../Modal"
import classNames from "classnames/bind"
import styles from "./signup-as-artist"
import {Link} from "react-router-dom"

const cx = classNames.bind(styles)

export default ({
                  isActive,
                  handleChange,
                  openModal,
                  closeModal,
                  modalIsOpen
                }) => (
  <div className={cx("container")}>
    <div className={cx("checkbox-container")}>
      <input
        type="checkbox"
        id="registerAsArtist"
        checked={isActive}
        onChange={handleChange}
      />
      <label htmlFor="registerAsArtist">Create an Artist Profile</label>
    </div>

    <button
      className={cx("more-info-button")}
      onClick={event => {
        event.preventDefault()
        openModal()
      }}
    >
      Memberships
    </button>
    <Modal closeModal={closeModal} modalIsOpen={modalIsOpen}>
      <h2>Artist Memberships and Subscriptions</h2>
      <ul>
        <li>
          To create an artist profile on Foundwork, you first need to create an artist membership.
        </li>
        <li>
          Artist memberships have a 4 USD monthly subscription which starts with a 30 day free trial, renews automatically
          at the end of each billing cycle, and can be canceled at any time.
        </li>
        <li>
          Your membership includes a full artist profile on Foundwork with the ability to share your CV, an artist
          statement, and unlimited artworks.
        </li>
        <li>
          For more information, please see our{" "}
          <Link to="/faq" target="_blank">
            FAQ
          </Link>{" "}
          or contact us at{" "}
          <a
            href="mailto:support@foundwork.art"
            rel="noopener noreferrer"
            target="_blank"
          >
            support@foundwork.art
          </a>
        </li>
      </ul>
    </Modal>
    <p className={cx("copy")}>
      An artist account and subscription is needed to create an artist profile
      on Foundwork. For more information about artist accounts and eligibility,
      please <Link to="/">click here</Link>.
    </p>
  </div>
)
