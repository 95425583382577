import React, { useState } from "react"
import styled from "styled-components"
import Subtitle from "@/ui/text/Subtitle"
import TextBlock from "@/ui/text/TextBlock"
import PaymentForm from "@/payments/PaymentForm"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import { forMobileOnly } from "@/ui/styles/breakpoints"
import { TokenResult } from "@stripe/stripe-js"
import { Coupon } from "@/coupon/Coupon"
import api, { SignupSubmission } from "@/api"
import { connect } from "react-redux"
import analytics from "@/analytics"
import UserSession from "@/userSession"
import { Dispatch } from "redux"
import { useHistory } from "react-router"
import { welcomeRoute } from "../../constants"

const Container = styled.div`
  ${globalStylesCss};

  max-width: 420px;
  width: 100%;
  margin: 4em auto;

  @media (${forMobileOnly}) {
    padding: 0 var(--page-minimal-gutter);
  }

  & > ${TextBlock} {
    margin-bottom: 2.5em;
  }
`

interface CreateArtistMembershipProps {
  userData: SignupData
  dispatchOnAuthenticationSuccess: () => void
}

const CreateArtistMembership = ({
  userData,
  dispatchOnAuthenticationSuccess,
}: CreateArtistMembershipProps) => {
  const { push } = useHistory() // TODO Update react-router(-dom) to v6

  const [subscriptionError, setSubscriptionError] = useState<string | undefined>(undefined)

  const signupUser = async (paymentDetails: Pick<SignupSubmission, "payment">) => {
    const { error, user } = await api.signup({
      user: userData,
      ...paymentDetails,
    })

    if (error) {
      setSubscriptionError("Error processing payment information")
      return () => {}
    }

    return () => {
      analytics.emitEvent("Payment Submit")
      UserSession.save(user)

      dispatchOnAuthenticationSuccess()

      push(welcomeRoute)
    }
  }

  const handlePayPalSubscription = async (subscriptionId: string) => {
    return await signupUser({ payment: { paypalId: subscriptionId } })
  }

  const handleStripeSubscription = async (token: TokenResult, coupon?: Coupon) => {
    return await signupUser({ payment: { stripeToken: token, coupon: coupon?.code } })
  }

  const handleFreeMemberSubscription = async (coupon: Coupon) => {
    return await signupUser({ payment: { coupon: coupon.code } })
  }

  return (
    <Container>
      <Subtitle>Create an Artist Membership</Subtitle>
      <TextBlock>
        <ul>
          <li>
            Artist memberships have a 4 USD monthly subscription that starts with a 30 day free trial, renews
            automatically at the end of each billing cycle, and can be canceled at any time.
          </li>
          <li>
            Please enter your credit or debit card or use PayPal as a payment method for your subscription.
          </li>
        </ul>
      </TextBlock>
      <PaymentForm
        submitMessage="Subscribe and Create Membership"
        enableCoupon
        onPayPalSubscriptionCreated={handlePayPalSubscription}
        onCreditCardTokenAcquired={handleStripeSubscription}
        onPermanentFullDiscount={handleFreeMemberSubscription}
        initialError={subscriptionError}
      />
    </Container>
  )
}

interface SignupData {
  firstName: string
  lastName: string
  email: string
  password: string
}

// TODO Get rid of redux
const mapStateToProps = ({ funnel }: { funnel: { user: SignupData } }) => {
  return {
    userData: funnel.user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchOnAuthenticationSuccess: () => {
    dispatch({ type: "AUTHENTICATION_SUCCESS", payload: { currentUser: UserSession } })
    dispatch({ type: "SET_AS_ARTIST", payload: {} })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateArtistMembership)
