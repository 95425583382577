import { createActions } from "./utils"

/*
The createActions thing is here to save you a bunch of typing. It's a shorthand for

    const HELLO_WORLD = 'HELLO_WORLD';
    function helloWorld() {
      return { type: HELLO_WORLD, payload: 'FNORD' };
    }

Naked strings passed as trailing arguments are turned into default actions in the form:

   const SET_KIND = 'SET_KIND';
   function setKind(arg1) {
     return { type: SET_KIND, payload: arg1 };
   }
*/
export const { actions, types } = createActions(
  { HELLO_WORLD: () => ({ payload: "FNORD" }) },
  "TOGGLE_REGISTER_AS_ARTIST",
  "AUTHENTICATION_SUCCESS",
  "LOG_OUT",
  "RESET_AUTH_FORM",
  "REQUEST_AUTH_FORM_SUBMIT",
  "RECEIVE_AUTH_FORM_SUBMIT",
  "AUTH_FORM_SUBMIT_FAILURE",
  "RESET_SEARCH_FILTERS",
  "UPDATE_SEARCH_ORDER",
  "ADD_SEARCH_FORMAT",
  "REMOVE_SEARCH_FORMAT",
  "RESET_SEARCH_FORMATS",
  "ADD_SEARCH_CATEGORY",
  "REMOVE_SEARCH_CATEGORY",
  "RESET_SEARCH_CATEGORIES",
  "SELECT_SEARCH_PROGRAM_YEAR",
  "SELECT_SEARCH_PROGRAM_YEAR_RANGE",
  "REMOVE_PROGRAM_YEAR",
  "ADD_SEARCH_DEGREE_TYPE",
  "REMOVE_SEARCH_DEGREE_TYPE",
  "ADD_SEARCH_ORGANIZATION",
  "REMOVE_SEARCH_ORGANIZATION",
  "ADD_SEARCH_MFA_PROGRAM",
  "REMOVE_SEARCH_MFA_PROGRAM",
  "RESET_SEARCH_ORG_OR_SCHOOL",
  "UPDATE_LOCATION_SEARCH",
  "RESET_LOCATION_SEARCH",
  "RESET_SEARCH_ARTIST_DETAILS",
  "SELECT_SEARCH_PAGE",
  "VALIDATE_SEARCH",
  "ADD_ARTWORK_IMAGES",
  "ADD_ARTWORK_VIDEO",
  "REMOVE_ARTWORK_IMAGE",
  "REMOVE_ARTWORK_PRIMARY_IMAGE",
  "REMOVE_ARTWORK_VIDEO",
  "RESET_ARTWORK_FORM",
  "ADD_ARTWORK_FORMAT",
  "REMOVE_ARTWORK_FORMAT",
  "ADD_ARTWORK_CATEGORY",
  "REMOVE_ARTWORK_CATEGORY",
  "OPEN_ARTWORK_FORM",
  "CLOSE_ARTWORK_FORM",
  "BEGIN_ARTWORK_SAVE",
  "FINISH_ARTWORK_SAVE",
  "FAIL_ARTWORK_SAVE",
  "BEGIN_EDITING_ARTWORK",
  "TOGGLE_PROFILE_IMAGE_FORM",
  "CLOSE_PROFILE_IMAGE_FORM",
  "SELECT_PROFILE_IMAGE",
  "RECEIVE_EXPLORE_ARTWORK",
  "INVALIDATE_EXPLORE_ARTWORK",
  "OPEN_ARTWORK_PREVIEW",
  "CLOSE_ARTWORK_PREVIEW",
  "INCREMENT_ARTWORK_PREVIEW",
  "GET_SECTION_SUCCESS",
  "UPDATE_CV_FORM",
  "UPDATE_ARTIST_STATEMENT_FORM",
  "UPDATE_PROFILE_INPUT",
  "UPDATE_PROFILE_LOCATION",
  "ADD_USER_RELATION",
  "REMOVE_USER_RELATION",
  "UPDATE_PERSONAL_INFO",
  "UPDATE_PASSWORD_INFO",
  "UPDATE_SCHOOL",
  "RESET_SCHOOL",
  "BEGIN_SCHOOL_MUTATION",
  "END_SCHOOL_MUTATION",
  "OPEN_SCHOOL_FORM",
  "CLOSE_SCHOOL_FORM",
  "OPEN_SIGNUP_MODAL",
  "CLOSE_SIGNUP_MODAL",
  "TOGGLE_LOGIN_MODAL",
  "CLOSE_LOGIN_MODAL",
  "CLOSE_UNPUBLISHED_NOTICE",
  "BEGIN_CMS_MUTATION",
  "END_CMS_MUTATION",
  "TOGGLE_SETTINGS_FORM",
  "TOGGLE_SETTINGS_SAVING",
  "TOGGLE_CANCELING",
  "CLOSE_HEADER_MENU",
  "CLOSE_HEADER_USER_MENU",
  "CLOSE_MOBILE_SEARCH_OVERLAY",
  "TOGGLE_CONTACT_FORM",
  "TOGGLE_HEADER_USER_MENU",
  "TOGGLE_MOBILE_SEARCH_OVERLAY",
  "UPDATE_CONTACT_FORM_FIELD",
  "SET_FUNNEL_DATA",
  "BEGIN_CONTACT_FORM_PROGRESS",
  "END_CONTACT_FORM_PROGRESS",
  "RESET_CONTACT_FORM",
  "CLEAR_SECTION",
  "TOGGLE_ARTIST_DETAIL_TAB",
  "CLOSE_REACTIVATION_MODAL",
  "UPDATE_USER_ARTWORK_LIST",
  "PARTIALLY_UPDATE_USER_ARTWORK",
  "REMOVE_USER_ARTWORK_FROM_LIST",
  "CHANGE_ARTWORK_POSITION"
)
