import React from "react"
import styles from "../ContactForm/contact-form"
import classNames from "classnames/bind"
import ContactForm from "../ContactForm"
import LoginModal from "../LoginModal"
import {useMutation} from "@apollo/client"
import {ArtistDetailQuery, UpdateSavedArtist} from "../../queries/artists.js"

const cx = classNames.bind(styles)

const ArtistDetailSubheaderActions = (props) => {
  const [mutate] = useMutation(UpdateSavedArtist)

  const handleToggleSaveArtist = (e) => {
    const {userId, slug} = props

    e.preventDefault()

    if (!userId) {
      props.toggleLoginModalOpen()
      return false
    }

    mutate({
      variables: {userId, slug},
      refetchQueries: [
        {
          query: ArtistDetailQuery,
          variables: {
            slug,
            userId,
          },
        },
      ],
    })
    e.target.blur()
  }

  return (
    <div className={cx("container")}>
      <button
        className={cx(["btn", `${props.savedByUser ? "btn-gray" : "btn-white"}`])}
        onClick={(e) => handleToggleSaveArtist(e)}
      >
        {props.savedByUser ? "Saved" : "Save"}
      </button>
      <button className={cx(["btn", "btn-dark-gray", "open-form"])} onClick={props.toggleOpen}>
        Contact Artist
      </button>
      {props.open && <ContactForm {...props} asGuest={!props.userId} />}
      {props.openLoginModal && <LoginModal {...props} />}
    </div>
  )
}

export default ArtistDetailSubheaderActions
