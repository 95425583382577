import React from "react"
import LoginForm from "../../containers/login/LoginForm"
import styles from "./login-modal"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

const LoginModal = ({ toggleLoginModalOpen }) => (
  <div className={cx("overlay")}>
    <div className={cx("container")}>
      <button className={cx("close-button")} onClick={toggleLoginModalOpen}>
        &times;
      </button>
      <LoginForm title={"Log in to Save Artists"} />
    </div>
  </div>
)

export default LoginModal
