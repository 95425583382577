import React from "react"
import styles from "./modal"
import classNames from "classnames/bind"

const cx = classNames.bind(styles)

export default ({ children, closeModal, modalIsOpen }) => (
  <div className={cx({ container: true, active: modalIsOpen })}>
    <div className={cx("modal")}>
      <div className={cx("middle")}>{children}</div>
      <div className={cx("bottom")}>
        <button
          className={cx("close")}
          onClick={event => {
            event.preventDefault()
            closeModal()
          }}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)
