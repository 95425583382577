import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { signupMember, validateCredentials } from "../../../services/auth"
import sendEvent from "../../../services/tracker"
import { actions } from "../../../actions.js"
import {
  subscriptionRoute,
  homeRoute,
  loginRoute,
  signupFields
} from "../../../constants"
import EntryForm from "../../../components/EntryForm"
import CurrentUser from "../../../currentUser.js"
import LoginFlowWrapper from "../../../components/LoginFlowWrapper"
import LoginFlowPrompt from "../../../components/LoginFlowPrompt"
import SignupAsArtist from "../../../components/SignupAsArtist"
import SignupTerms from "../../../components/SignupTerms"

class SignupForm extends Component {
  componentDidMount() {
    document.querySelector("html").classList.remove("no-scroll")
  }

  componentWillUnmount() {
    // Reset form in case they switch to other auth page
    this.props.resetForm()
  }

  handleSubmit(form) {
    const { registerAsArtist, createArtist, createMember } = this.props

    registerAsArtist ? createArtist(form) : createMember(form)
  }

  render() {
    const {
      toggleRegisterAsArtist,
      registerAsArtist,
      openModal,
      closeModal,
      modalIsOpen,
      error,
      loading
    } = this.props

    return (
      <div>
        <LoginFlowWrapper
          title={"Sign Up"}
          buttonText={"Sign Up"}
          registerAsArtist={registerAsArtist}
          onSubmit={this.handleSubmit.bind(this)}
          error={error}
          loading={loading}
        >
          <EntryForm fields={signupFields} />
          <SignupAsArtist
            isActive={registerAsArtist}
            handleChange={toggleRegisterAsArtist}
            openModal={openModal}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
          />
          <SignupTerms />
        </LoginFlowWrapper>

        <LoginFlowPrompt
          text={"Already have an account? "}
          url={loginRoute}
          linkText={"Log In"}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { registerAsArtist, modalIsOpen } = state.auth || {}
  const { error, loading } = state.authForm
  return { registerAsArtist, modalIsOpen, error, loading }
}

const mapDispatchToProps = dispatch => {
  const {
    authenticationSuccess,
    toggleRegisterAsArtist,
    openSignupModal,
    closeSignupModal,
    requestAuthFormSubmit,
    receiveAuthFormSubmit,
    authFormSubmitFailure,
    setFunnelData,
    resetAuthForm
  } = actions

  return {
    resetForm: () => {
      dispatch(resetAuthForm())
    },
    createArtist: form => {
      dispatch(requestAuthFormSubmit())
      validateCredentials(form, ({ err, response }, data) => {
        if (err || !response.ok) {
          const errors = JSON.parse(response.text)
            .map(err => {
              return err.message
            })
            .join(". ")
          dispatch(authFormSubmitFailure({ error: errors }))
          return
        }
        sendEvent("send", "event", {
          eventCategory: "Button",
          eventAction: "click",
          eventLabel: "Signup Submit",
          isArtist: true
        })
        dispatch(
          setFunnelData({
            type: "user",
            data
          })
        )
        dispatch(receiveAuthFormSubmit())
        dispatch(push(subscriptionRoute))
      })
    },
    createMember: form => {
      dispatch(requestAuthFormSubmit())

      validateCredentials(form, ({ err, response }, data) => {
        if (err || !response.ok) {
          const errors = JSON.parse(response.text)
            .map(err => {
              return err.message
            })
            .join(". ")
          dispatch(authFormSubmitFailure({ error: errors }))
          return
        }

        signupMember(form, ({ err, response }) => {
          if (err || !response.ok) {
            dispatch(
              authFormSubmitFailure({
                error: "An error occurred"
              })
            )
            return
          }
          sendEvent("send", "event", {
            eventCategory: "Button",
            eventAction: "click",
            eventLabel: "Signup Submit",
            isArtist: false
          })

          dispatch(receiveAuthFormSubmit())
          const { body } = response
          CurrentUser.create(body)

          dispatch(
            authenticationSuccess({
              currentUser: CurrentUser
            })
          )
          dispatch(push(homeRoute))
        })
      })
    },
    toggleRegisterAsArtist: () => dispatch(toggleRegisterAsArtist()),
    openModal: () => dispatch(openSignupModal()),
    closeModal: () => dispatch(closeSignupModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm)
