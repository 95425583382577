import React, { useState } from "react"
import CreditCardInput from "@/ui/forms/CreditCardInput"
import PayPalSubscriptionButton from "@/payments/paypal/PayPalSubscriptionButton"
import styled from "styled-components"
import Button, { ButtonSize } from "@/ui/elements/Button"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import { TokenResult } from "@stripe/stripe-js"
import useCreditCardProcessing from "@/payments/stripe/useCreditCardProcessing"
import CouponField from "@/coupon/CouponField"
import { Coupon } from "@/coupon/Coupon"

type Callback = () => void

type DiscountOptions =
  | {
      onPermanentFullDiscount: (coupon: Coupon) => Promise<Callback>
      enableCoupon?: true
    }
  | {
      onPermanentFullDiscount?: never
      enableCoupon?: false
    }

type PaymentFormProps = {
  buttonSize?: ButtonSize
  submitMessage?: string
  dividerMessage?: string
  initialError?: string
  onError?: (error: string) => void
  onPayPalSubscriptionCreated: (subscriptionId: string) => Promise<Callback>
  onCreditCardTokenAcquired: (token: TokenResult, coupon?: Coupon) => Promise<Callback>
} & DiscountOptions

const Container = styled.div`
  ${globalStylesCss};

  display: flex;
  flex-direction: column;
  max-width: 750px; // This is dictated by the PayPal button, which doesn't expand past 750px

  gap: var(--margin-primary);

  & > Button {
    margin-top: var(--margin-primary);
  }
`

const ErrorMessage = styled.div`
  ${globalStylesCss};

  font-size: var(--font-size-small);
  text-align: center;
  letter-spacing: var(--letter-spacing-default);
  display: inline-block;
  color: var(--font-color-error);
`

const Divider = styled.div`
  ${globalStylesCss};

  text-align: center;

  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-large);
  color: var(--font-color-secondary);
  letter-spacing: var(--letter-spacing-large);
  line-height: normal;
`

const PaymentForm = ({
  buttonSize = ButtonSize.DEFAULT,
  submitMessage = "Pay",
  dividerMessage = "or",
  initialError = undefined,
  onError = () => {},
  onPayPalSubscriptionCreated,
  onCreditCardTokenAcquired,
  onPermanentFullDiscount,
  enableCoupon = false,
}: PaymentFormProps) => {
  const [error, setError] = useState<string | undefined>(initialError)
  const [isSaving, setIsSaving] = useState(false)
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined)

  const requiresPaymentInfo = !coupon || coupon.requiresPayment

  const processCreditCard = useCreditCardProcessing()

  const acquireCreditCardToken = async () => {
    var finalCallback = () => {}

    setIsSaving(true)

    try {
      const token = await processCreditCard()

      if (token) {
        finalCallback = await onCreditCardTokenAcquired(token, coupon)
        setError(undefined)
      } else {
        setError(
          "Couldn't obtain credit card information, please try again or try a different payment method."
        )
      }
    } catch (e) {
      setError("Failed to process credit card, please try again or try a different payment method.")
      onError("Failed to process credit card information")
    }

    setIsSaving(false)

    finalCallback()
  }

  const notifyPayPalSubscriptionCreated = async (subscriptionId: string) => {
    const finalCallback = await onPayPalSubscriptionCreated(subscriptionId)
    finalCallback()
  }

  const notifyPermanentFullDiscount = async () => {
    if (onPermanentFullDiscount === undefined)
      throw "impossible state: coupons are disabled but the full discount notification has been triggered"

    setIsSaving(true)

    const finalCallback = await onPermanentFullDiscount(coupon!!)

    setIsSaving(false)

    finalCallback()
  }

  // TODO Switch submit action when !requiresPaymentInfo + always return coupon information
  return (
    <Container>
      {requiresPaymentInfo && <CreditCardInput labelSize={1} />}
      {enableCoupon && <CouponField onCouponUpdate={setCoupon} />}
      <Button
        $size={buttonSize}
        onClick={requiresPaymentInfo ? acquireCreditCardToken : notifyPermanentFullDiscount}
        disabled={isSaving}
      >
        {isSaving ? "Saving..." : submitMessage}
      </Button>
      <Divider>{dividerMessage}</Divider>
      <PayPalSubscriptionButton
        onSubscriptionCreated={notifyPayPalSubscriptionCreated}
        onError={() => {
          setError(
            "Failed to create PayPal subscription, please try again or try a different payment method."
          )
          onError("Failed to create PayPal subscription")
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export default PaymentForm
