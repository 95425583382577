import { createGlobalStyle, css } from "styled-components"

/*
 * NOTE
 *
 * We need some of the CSS variables to be accessible from Javascript so that we can keep their value in sync with
 * props we pass to components that don't accept a full custom CSS styling but rather accept individual customization
 * values (particularly, the only way to style Stripe Elements is to provide an object with values for the supported CSS
 * customizations. The value of those customization comes from the CSS variables, but without this workaround we'd be
 * forced to duplicate the values and getting things out of sync pretty quickly.)
 *
 * I'm not making all variables accessible in this fashion because I don't think it's a very good practice and it could
 * lead to confusion by having multiple ways to inject those values in the CSS (and the naming of this variable is
 * intentionally verbose exactly for this reason). Plus this way we would lose some useful type checking / hinting from
 * the IDEs.
 */
// @ts-ignore
export const programmaticallyAccessibleVariables = {
  fontFamilyPrimary: "Roboto, sans-serif",
  fontColorPrimary: "#111111", // !!! Note the lack of proper variable referencing here
  letterSpacingDefault: ".7px", // 0.04375rem; // .7px on a reference font size of 16px TODO Change after migration to new CSS architecture is over
  fontSizePrimary: "16px", // 1rem; TODO Change after migration to new CSS architecture is over
  lineHeightDefault: "2",
  colorGray19: "#CFCFCF",
  backgroundColorPrimary: "#FFFFFF", // !!! Note the lack of proper variable referencing here
}

const v = programmaticallyAccessibleVariables // Don't want to clutter the code below too much

// TODO This is a workaround to prevent injecting global styles that will clash with the existing CSS code. If an when
// the migration is done, we should remove injecting this CSS snippets into every components and just streamline it as
// global rules
export const variablesCss = css`
  /* Colors */
  --color-blue: #4a90e2;
  --color-green: #00ff00;
  --color-red: #fe5454;
  --color-white: #ffffff;
  --color-gray-93: #111111;
  --color-gray-71: #494949;
  --color-gray-19: ${v.colorGray19};
  --color-gray-17: #d4d4d4;
  --color-gray-12-transparent: rgba(30, 30, 30, 0.8);
  --color-gray-4: #f5f5f5;

  --background-color-primary: var(--color-white);
  --dialog-background-color: var(--color-gray-12-transparent);

  /* Typography */
  --font-family-heading: "GT Eesti Pro", sans-serif;
  --font-family-primary: ${v.fontFamilyPrimary};
  --font-color-primary: var(--color-gray-93);
  --font-color-secondary: var(--color-gray-71);
  --font-color-tertiary: var(--color-gray-19);
  --font-color-inverted-primary: var(--color-white);
  --font-color-error: var(--color-red);
  --line-height-default: ${v.lineHeightDefault};
  --font-size-primary: ${v.fontSizePrimary};
  --font-size-small: 12px; // 0.75rem; TODO Change after migration to new CSS architecture is over
  --font-size-medium: 14px; // 0.875rem; TODO Change after migration to new CSS architecture is over
  --font-size-large: 22px; // 1.375rem; TODO Change after migration to new CSS architecture is over
  --font-size-heading: 32px; // 2rem; TODO Change after migration to new CSS architecture is over
  --font-weight-heading: 500;
  --font-size-subheading: 24px; // 1.5rem; TODO Change after migration to new CSS architecture is over
  --font-weight-subheading: 500;
  --letter-spacing-default: ${v.letterSpacingDefault};
  --letter-spacing-large: 1px; // 0.0625rem; // equivalent to 1px at 16px base font size TODO Change after migration to new CSS architecture is over
  --letter-spacing-extra-large: 1.25px; // 0.078125rem; // equivalent to 1.25px at 16px base font size TODO Change after migration to new CSS architecture is over

  /* White space */
  --input-height-primary: 48px; // 3rem; TODO Change after migration to new CSS architecture is over
  --padding-primary: 16px; // 1rem; TODO Change after migration to new CSS architecture is over
  --margin-primary: 16px; // 1rem; TODO Change after migration to new CSS architecture is over
  --inner-width: 1390px;
  --page-gutter: 135px;
  --page-minimal-gutter: 24px;
  --form-width: 960px;
  --dialog-message-max-width: 450px;
  --popup-max-width: 576px;

  /* Fixed elements */
  --header-height: 72px;
  --header-height-desktop: 80px;
  --footer-height: 100px;
  --footer-height-desktop: 100px;

  /* Shadows */
  --box-shadow: 0 2px 10px hsla(0, 0%, 0%, 0.2);
`

const Variables = createGlobalStyle`
  :root {
    ${variablesCss}
  }
`

export default Variables
